

































$cor: #0091ff;
  .relatorio {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    .iframe {
      margin-top: 20px;
      min-height: calc(100vh - 112px + 24px);
      border: none;
      margin: -24px;
      width: calc(100% + 50px);
    }
  }
  .v-progress-circular{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: -1 !important;
  }
